import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import TableServices from 'services/table';
import { trelloLabels } from 'theme/trelloLabels';

const MultipleCardComponent = ({ cardItem, index, handleBlur, handleChange, fieldName, array }) => {
    const dispatch = useDispatch();
    const { allProjectLabels } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getAllProjectLabel());
    }, [])
    // console.log("allWorkCardLabels--", allWorkCardLabels);
    const arrayValueChange = (obj) => {
        const Index = array?.findIndex(e => e?.trello_id == obj?.trello_id)
        if (index !== -1) {
            array[Index] = obj
            // console.log("onchane new obj ", array);
            handleChange(array)

        }
    }
    const onItemValueChange = (value) => {
        console.log("onchane", value);
        const hourChange = {
            ...cardItem,
            ...{ Estimated_Hours: value }
        }
        arrayValueChange(hourChange)
    }
    const onWorkCardLabelValueChange = (value) => {
        let finalArray = value.map(function (obj) {
            return obj.value;
        });
        const labelChange = {
            ...cardItem,
            ...{ labelNames: finalArray }
        }
        arrayValueChange(labelChange)
    }
    return (
        <>
            <Grid item md={5}>
                <TextField
                    fullWidth
                    disabled
                    value={cardItem.child_card_title}
                    placeholder="Mechanical"
                    variant="outlined"
                    name="cardItem.child_card_title"
                    multiline
                    className="edit-value"
                    style={{
                        maxWidth: "250px"
                    }}
                />
            </Grid>
            <Grid item md={2}>
                <TextField
                    // fullWidth
                    // error={Boolean(touched.Spent_Mech_Hours && errors.Spent_Mech_Hours)}
                    // helperText={touched.Spent_Mech_Hours && errors.Spent_Mech_Hours}
                    value={cardItem?.Estimated_Hours}
                    placeholder="hour"
                    variant="outlined"
                    name={`cardItem?..${index}.Estimated_Hours`}
                    type='number'
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={(e) => {
                        onItemValueChange(e?.target?.value)

                    }}
                    multiline
                    className="edit-value"
                    style={{
                        maxWidth: "60px",
                        marginLeft: "20px"
                    }}
                />
            </Grid>
            <Grid item md={5}>
                <Select
                    isMulti
                    value={cardItem?.labelNames?.map(label => ({ 'value': label, 'label': label }))}
                    options={allProjectLabels}
                    placeholder="Work card Labels.."
                    // styles={customStyles}
                    maxMenuHeight={200}
                    onChange={(xx) => {
                        onWorkCardLabelValueChange(xx)
                    }}
                />
            </Grid>
        </>
    )
}

export default MultipleCardComponent;