import { STORE_TABLE_DATA, STORE_MANAGERS_DATA, STORE_FOLDERS_DATA, STORE_ALL_PROJECT_LABEL,STORE_ALL_WORKCARD_LABEL, STORE_TRELLO_USERS_DATA, STORE_UPDATED_CUSTOM_ORDER, STORE_UPDATED_CARD_DETAIL, STORE_UPDATED_CHAT_CONVERSATION, UPDATE_TABLE_ROW, UPDATE_TABLE_DATA, CLEAR_CONVERSATION_DATA } from "@jumbo/constants/ActionTypes";

export const setTableData = data => {
    return dispatch => {
      dispatch({
        type: STORE_TABLE_DATA,
        payload: data
      });
    };
  };
export const setFoldersData = data => {
    return dispatch => {
      dispatch({
        type: STORE_FOLDERS_DATA,
        payload: data
      });
    };
  };
export const setAllprojectLabel = data => {
    return dispatch => {
      dispatch({
        type: STORE_ALL_PROJECT_LABEL,
        payload: data
      });
    };
  };
export const setAllworkCardLabel = data => {
    return dispatch => {
      dispatch({
        type: STORE_ALL_WORKCARD_LABEL,
        payload: data
      });
    };
  };
export const setManagersData = data => {
    return dispatch => {
      dispatch({
        type: STORE_MANAGERS_DATA,
        payload: data
      });
    };
  };
export const setTrelloUsersData = data => {
    return dispatch => {
      dispatch({
        type: STORE_TRELLO_USERS_DATA,
        payload: data
      });
    };
  };
export const setUpdatedCustomOrder = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_CUSTOM_ORDER,
        payload: data
      });
    };
  };
export const setUpdatedCardDetails = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_CARD_DETAIL,
        payload: data
      });
    };
  };
export const setUpdatedChatConversation = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_CHAT_CONVERSATION,
        payload: data
      });
    };
  };
  export const UpdateTableRow = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_TABLE_ROW,
        payload: rowData
      });
    };
  };
  export const UpdateTableData = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_TABLE_DATA,
        payload: rowData
      });
    };
  };
  export const clearConversationData = data => {
    return dispatch => {
        dispatch({
            type: CLEAR_CONVERSATION_DATA,
        });
    };
};