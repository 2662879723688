import React, { useState } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        // borderRadius: "7px",
        "& .MuiTypography-h6": {
            fontFamily: '"Poppins", sans-serif;',
            fontWeight: 'normal'
        },
    },

}));

const TableActions = ({ getViewName }) => {
    const classes = useStyles();
    const [tableView, setTableView] = useState('');
    const handleChange = (event) => {
        setTableView(event)
        getViewName(event)
    };
    return (
        <Box display="flex" alignItems="center" mr={4} className={classes.root}>
            <Typography variant='h6' style={{ marginRight: "16px", }}>
                Sort by:
            </Typography>
            <select
                className="sort-date-by-select"
                onChange={(e) => handleChange(e.target.value)}
                value={tableView}
            >
                <option value="Latest">Latest</option>
                <option value="CustomOrder">Custom order</option>
                <option value="CustomOrder2">Custom order2</option>
                <option value="DeliveredProject">Delivered Project</option>
                <option value="DeliveredReadyProject">Delivery Ready Project</option>
                <option value="InProgress">In Progress</option>
                <option value="NeedFinalReview">Need Final Review</option>
                <option value="NeededInfo">Needed Info</option>
                <option value="Todo">To Do</option>
                {/* <option value="Last Month">Last Month</option>
                <option value="Due Date">Due Date</option> */}
                <option value="All">All</option>
            </select>
        </Box>
    )
}

export default TableActions;