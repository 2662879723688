import React, { useEffect, useState } from 'react';
import { AppTheme } from "theme/customeTheme";
import { trelloLabels } from 'theme/trelloLabels';

const CustomBadge = ({ name, child_cards, labelStatus, cardId, promise, PrelimDate, FinalDate }) => {
    const [labelBackgroundColor, setLabelBackgroundColor] = useState();
    const [labelBorderColor, setLabelBorderColor] = useState();
    useEffect(
        () => {
            if (promise !== null && (PrelimDate !== null || FinalDate !== null)) { //Green
                setLabelBackgroundColor(AppTheme.CustomLabelcolors.green);
                setLabelBorderColor(AppTheme.CustomLabelcolors.green);
            }
            if (promise !== null && (PrelimDate == null && FinalDate == null)) { //light brown 
                setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.lightBrown);
                setLabelBorderColor(AppTheme.BadgeBorderColors.lightBrown);
            }
            if (promise === null) { //light brown 
                setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.lightBrown);
                setLabelBorderColor(AppTheme.BadgeBorderColors.lightBrown);
            }
            if (labelStatus?.includes(trelloLabels.labelName.HourADay)) { // light blue
                setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.lightBlue);
                setLabelBorderColor(AppTheme.BadgeBorderColors.lightBlue);
            }
            if (labelStatus?.includes(trelloLabels.labelName.PS)) { // orange
                setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.orange);
                setLabelBorderColor(AppTheme.BadgeBorderColors.orange);
            }
            if (labelStatus?.includes(trelloLabels.labelName.BC)) { // orange
                setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.green);
                setLabelBorderColor(AppTheme.BadgeBorderColors.green);
            }
            if (labelStatus?.includes(trelloLabels.labelName.BlockedByInfo)) { //red
                setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.red);
                setLabelBorderColor(AppTheme.BadgeBorderColors.red);
            }
            if (child_cards.length !== 0) {
                for (let i = 0; i < child_cards.length; i++) {
                    let temp = child_cards[i]['labelNames'];
                    const findBlockedByInfo = temp.some(label => (label == trelloLabels.labelName.BlockedByInfo) || (label == trelloLabels.labelName.NeededInfo))
                    if (findBlockedByInfo == true) {
                        setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.red);
                        setLabelBorderColor(AppTheme.BadgeBorderColors.red);
                    }
                }
            }
        },
        [labelStatus]
    );
    return (
        <p title={name} className="text-truncate highest-priorities"
            style={{
                backgroundColor: labelBackgroundColor,
                borderColor: labelBorderColor,

            }}><a style={{ color: 'black' }} href={'https://trello.com/c/' + cardId} target="_blank" rel="noreferrer">{name}</a></p>
    )
}

export default CustomBadge;