import { DefaultColumnFilter } from '@jumbo/components/TableComponents/table-utils'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { matchSorter } from 'match-sorter'
import React, { forwardRef, useEffect } from 'react'
import { useAsyncDebounce, useFilters, useGlobalFilter, useTable, useColumnOrder, usePagination, useSortBy } from 'react-table'
import { AppTheme } from 'theme/customeTheme'
import TablePagination from './TablePagination'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragIcon from '../../../assets/drag.svg';
import { useSticky } from 'react-table-sticky'
import DraggableRow from './DraggableRow'
import update from 'immutability-helper'
import { UpdateTableData } from 'redux/actions/Table.action'
import { useDispatch, useSelector } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import TableServices from 'services/table'
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1rem",
        '& .MuiPaginationItem-icon': {
            color: '#3F7786',
        },
        '& .MuiTableCell-root': {
            textAlign: "center",
            padding: "12px",
            fontFamily: '"Mulish", sans-serif',
            fontWeight: 600,
            minWidth: "160px",
            outline: 'none'
        },
        '& .MuiPaginationItem-outlined[aria-label="Go to next page"], & .MuiPaginationItem-outlined[aria-label="Go to previous page"], & .MuiPaginationItem-outlined[aria-label="Go to first page"], & .MuiPaginationItem-outlined[aria-label="Go to last page"]': {
            backgroundColor: '#DBE7EA',
            border: 0
        },
        '& .MuiPaginationItem-page.Mui-selected': {
            borderColor: '#3F7786',
        },
        '& .MuiPaginationItem-ellipsis': {
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            height: "32px"
        },
        '& .MuiSelect-select:focus': {
            borderRadius: 4,
        },
        '& .MuiTableCell-head': {
            color: AppTheme.colors.black,
            fontWeight: 600,
            outline: 'none'
        },
    },
    Pagination: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));
const DragIcon = (column) => {
    return (
        column === 'Sr_NO' || column === 'card_title' || column === 'DeliveryDate' || column === "selection" || column === "id" || column === "action" ? "" :
            <img src={dragIcon} alt="drag" className="ml-2 cursor-grab" />
    )
}
const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
    ...draggableStyle,
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    // background: isDragging && "#EFD0C2!important",
    background: "#EFD0C2!important",

    ...(!isDragging && { transform: "translate(0,0)" }),
    ...(isDropAnimating && { transitionDuration: "0.001s" })

    // styles we need to apply on draggables
});
function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val
const ReactMainTable = forwardRef(({ orderBy, offset, tableView, columns, data, applyFilters, itemsPerPage, currentPage, TotalLength, getPageData, orderByColumns, lastLockedIndex, updateCallBack }, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const initialState = {
        selectedFlatRows: [],
        pageIndex: currentPage,
        pageSize: itemsPerPage,
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        allColumns,
        headers,
        setColumnOrder,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, filters },
    } = useTable({
        columns,
        data,
        initialState,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        manualFilters: true,
        manualPagination: true,
        pageCount: Math.ceil(TotalLength / itemsPerPage),
    },
        useColumnOrder,
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy, useSticky,
        usePagination,
    )
    const handleChange = (e, p) => {
        gotoPage(p)
    };
    const onNumberPagesChanged = (val) => {
        setPageSize(Number(val))
        gotoPage(1)
    }
    const onApplyFilter = useAsyncDebounce(() => {
        applyFilters(filters)
    }, 200)
    useEffect(() => {
        onApplyFilter();
    }, [filters]);
    useEffect(() => {
        getPageData(pageIndex, pageSize)
        console.log("pageSize, pageIndex", pageIndex, pageSize);
    }, [pageSize, pageIndex]);
    const currentColOrder = React.useRef();
    const moveRow = (dragIndex, hoverIndex) => {
        console.log("moveRow::", dragIndex, hoverIndex);
        const dragRecord = data[dragIndex]
        dispatch(UpdateTableData(update(data, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
            ],
        })))
        const hoverRecord = data[hoverIndex]
        let finalCustomOrder = {};
        if (tableView === 'CustomOrder2') {
            console.log("CustomOrder2 dragRecord::", dragRecord?.projectMetaData);
            console.log("CustomOrder2 hoverRecord::", hoverRecord?.projectMetaData);
            if (dragIndex < hoverIndex) {
                finalCustomOrder = {
                    old_order: dragRecord?.projectMetaData?.order2,
                    new_order: hoverRecord?.projectMetaData?.order2,
                    trello_id: dragRecord?.projectMetaData?.trello_id
                }
            }

            if (hoverIndex < dragIndex) {
                finalCustomOrder = {
                    old_order: dragRecord?.projectMetaData?.order2,
                    new_order: hoverRecord?.projectMetaData?.order2,
                    trello_id: dragRecord?.projectMetaData?.trello_id
                }
            }
        }
        else {
            console.log("dragRecord::", dragRecord?.projectMetaData);
            console.log("hoverRecord::", hoverRecord?.projectMetaData);
            if (dragIndex < hoverIndex) {
                finalCustomOrder = {
                    old_order: dragRecord?.projectMetaData?.order,
                    new_order: hoverRecord?.projectMetaData?.order,
                    trello_id: dragRecord?.projectMetaData?.trello_id
                }
            }

            if (hoverIndex < dragIndex) {
                finalCustomOrder = {
                    old_order: dragRecord?.projectMetaData?.order,
                    new_order: hoverRecord?.projectMetaData?.order,
                    trello_id: dragRecord?.projectMetaData?.trello_id
                }
            }
        }

        console.log("finalCustomOrder::", finalCustomOrder);
        dispatch(TableServices.updateCustomOrder(finalCustomOrder, tableView, () => {
            updateCallBack()
        }));
    }
    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="table-container">
                <DndProvider backend={HTML5Backend}>
                    <TableContainer className={clsx(classes.root, "custom-scrollbar", "table-responsive")}>
                        <Table {...getTableProps()}
                            stickyHeader
                            aria-label="sticky table"
                            style={{ border: 'none', borderSpacing: '0', width: '100%', position: 'relative', }}
                        >
                            <TableHead>
                                {/* {headerGroups.map(headerGroup => ( */}
                                <DragDropContext
                                    // onDragUpdate={(result) => {
                                    //     console.log("debug result", result);
                                    //     if (result?.source?.index && result?.destination?.index)
                                    //         setColumnOrder(array_move(allColumns?.map(o => o.id), result?.source?.index, result?.destination?.index))
                                    // }}
                                    onDragStart={(e) => {
                                        console.log("debug onDragStart", e);
    
                                        currentColOrder.current = allColumns?.map(o => o.id);
                                    }}
                                    onDragUpdate={(dragUpdateObj, b) => {
                                        console.log("debug onDragUpdate", dragUpdateObj, b);
    
                                        const colOrder = [...currentColOrder.current];
                                        const sIndex = dragUpdateObj.source.index;
                                        const dIndex =
                                            dragUpdateObj.destination && dragUpdateObj.destination.index;
    
                                        if (typeof sIndex === "number" && typeof dIndex === "number") {
                                            colOrder.splice(sIndex, 1);
                                            colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                                            console.log('debug colOrder lastLockedIndex dragUpdateObj.source.index', colOrder, lastLockedIndex, dragUpdateObj.destination.index);
                                            if (lastLockedIndex < dragUpdateObj.destination.index)
                                                setColumnOrder(colOrder);
    
                                            // console.log(
                                            //   "onDragUpdate",
                                            //   dragUpdateObj.destination.index,
                                            //   dragUpdateObj.source.index
                                            // );
                                            // console.log(temp);
                                        }
                                    }}
                                    // onDragUpdate={(result) => {
                                    //     if (lastLockedIndex < dragUpdateObj.destination.index)
                                    //         setColumnOrder(array_move(visibleColumns?.map(o => o.id), result?.source?.index, result?.destination?.index))
                                    // }}
                                >
                                    <Droppable droppableId={"droppable"} direction="horizontal">
                                        {(droppableProvided, snapshot) => {
                                            return (
                                                <TableRow
                                                    ref={droppableProvided.innerRef}
                                                    {...droppableProvided.droppableProps}
                                                >
                                                    {
                                                        (tableView === 'CustomOrder' || tableView === 'CustomOrder2' || tableView === 'DeliveredReadyProject')
                                                        &&
                                                        <TableCell
                                                            style={{
                                                                backgroundColor: '#FFF6F6',
                                                                cursor: 'grab',
                                                                outline: 'none',
                                                            }}>
                                                            move
                                                        </TableCell>
                                                    }
                                                    {visibleColumns.map((column, headerIndex) => (
                                                        <Draggable
                                                            draggableId={column.id}
                                                            key={column.id}
                                                            index={headerIndex}
                                                            isDragDisabled={!column.accessor || column.sticky == 'left'}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <TableCell
                                                                        {...column.getHeaderProps()} className={`th align-middle border-top-0 border-bottom-0 
                                                                        ${snapshot.isDragging ? "shadow-sm" : ""} ${snapshot.isDragging ? "" : "fixed-column-" + headerIndex}`}
                                                                        onClick={() => orderByColumns(column.id)}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            ...getItemStyle(
                                                                                snapshot,
                                                                                provided.draggableProps.style
                                                                            ),
                                                                            backgroundColor: '#FFF6F6',
                                                                            // cursor: 'grab',
                                                                            // outline: 'none',
                                                                            // position: column?.sticky === 'left' ? 'sticky' : 'initial',
                                                                        }}>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            margin: '0 auto',
                                                                        }}
                                                                            className="custom-column-width">
                                                                            <a {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                                style={{ left: "auto", backgroundColor: '#FFF6F6' }}>{column.render('Header')}</a>
                                                                            {DragIcon(column.id)}
                                                                        </div>
                                                                    </TableCell>
                                                                )
                                                            }}
                                                        </Draggable>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </TableRow>
                                            )
                                        }}
                                    </Droppable>
                                </DragDropContext>
                                {/* ))} */}
                                <TableRow className="tr bg-white">
                                    {(tableView === 'CustomOrder' || tableView === 'CustomOrder2' || tableView === 'DeliveredReadyProject') &&
                                        <TableCell
                                            style={{
                                                backgroundColor: 'none',
                                                top: '45px',
                                            }}></TableCell>
                                    }
                                    {visibleColumns.map((column, index) => (
                                        <TableCell {...column.getHeaderProps()} className={"th align-middle border-top-0 border-bottom-0 bg-white fixed-column-" + index}
                                            style={{
                                                backgroundColor: 'none',
                                                top: '45px'
                                            }}>
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </TableCell>
                                    ))}
                                </TableRow>

                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    return prepareRow(row) || (
                                        <DraggableRow
                                            index={i}
                                            row={row}
                                            moveRow={moveRow}
                                            {...row.getRowProps()}
                                            tableView={tableView}
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DndProvider>
            </div>
            <TablePagination classes={classes} offset={offset} pageIndex={pageIndex - 1} pageSize={pageSize} TotalLength={TotalLength} handleChange={handleChange} onNumberPagesChanged={onNumberPagesChanged} />
        </React.Fragment>
    )
})
export default ReactMainTable