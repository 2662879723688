import ChatFooter from '@jumbo/components/TableComponents/Comments/ChatFooter';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useRef } from 'react';
import DialogTitleCustom from '../TableEditPopup/DialogTitleCustom';
import Conversation from "./Conversation";
import Sidebar from "./Sidebar";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Poppins", sans-serif',
      color: "#000000"
    },
  },

}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);


export default function CommentsDialogBox({ commentSection, projectInfo, label, openDialog, handleOpenDialog, handleCloseDialog }) {
  const classes = useStyles();
  const ref = useRef(null)
  // console.log("openDialog--", openDialog);
  // console.log("commentSection--", commentSection);
  return (
    <Box>
      {openDialog && <Dialog
        ref={ref}
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog || false}
        maxWidth="md"
        className={classes.root}
        TransitionComponent={Transition}
      >
        {/* <DialogTitleCustom id="alert-dialog-slide-title" className="dialog-title" onClose={handleCloseDialog}>Comments</DialogTitleCustom> */}

        <DialogTitle id="customized-dialog-title" className="dialog-title">
          Comments
        </DialogTitle>
        <DialogContent dividers className="comments-box">
          <Grid container>
            <Grid item xs={12} sm={9} md={9} style={{ paddingRight: 16, borderRight: "2px solid rgba(0, 109, 143, 0.5)" }}>
              <Conversation TitleInfo={commentSection} />
              <ChatFooter cardType={commentSection} />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Sidebar sideBarTitleInfo={commentSection} sideBarInfo={projectInfo} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>}
    </Box>
  );
}
