import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedChatConversation } from "redux/actions/Table.action";
import Axios from "services/config";
import { io } from "socket.io-client";
import { AppTheme } from "theme/customeTheme";
import { BASE_WS_URL } from "utils/globals";
import {clearConversationData} from "../../../../redux/actions/Table.action"

const colors = [
  "#D7D7D7",
  "#FFC0C0",
  "#C0D9FF",
  "#FFC0F9",
  "#D7D7D7",
  "#D7D7D7",
];
const Conversation = ({ TitleInfo }) => {
  // console.log("TitleInfo::", TitleInfo);
  const [conversation, setConversation] = React.useState([]);
  const [totalChat, setTotalChat] = React.useState(null);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { lastSentMessage } = useSelector(({ table }) => table);
  const mountedRef = useRef();
  // console.log("lastSentMessage--", lastSentMessage);
  // console.log("conversation--", conversation);

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const getThreadMessages = (pageNo) => {
    console.log("getThreadMessages fetching", fetching);
    if (fetching) {
      return;
    }

    setFetching(true);
    if (page <= totalPage) {
      try {
        Axios.get(
          `thread_message/messages/${TitleInfo._id}?page=${pageNo}&limit=${limit}`
        )
          .then((data) => {
            if (data.status == 200) {
              setConversation([
                ...data?.data?.threadMessages?.reverse(),
                ...conversation,
              ]);
              setTotalChat(data?.data?.totalItems);
              setTotalPage(data?.data?.totalPages);
              setPage(pageNo);
              setFetching(false);
              setTimeout(() => {
                var objDiv = document.getElementById("scrollableDiv");
                objDiv.scrollTop = objDiv?.scrollHeight || 0;
              }, 200);
            }
          })
          .catch(() => null);
      } catch (error) {}
    }
  };
  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );

  useEffect(() => {
    if (lastSentMessage?.data) {
      setConversation([...conversation, lastSentMessage?.data]);
      setTimeout(() => {
        var objDiv = document.getElementById("scrollableDiv");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 200);
    }
  }, [lastSentMessage]);
  useEffect(() => {

    getThreadMessages(1);
    mountedRef.current = true;
    const socket = io(BASE_WS_URL);
    if (socket) {
      socket.emit("threadCreation", TitleInfo?._id);
      socket.on("threadCreationSuccess", (res) => {
        if (res) {
          const newMsg = JSON.parse(res);
          dispatch(setUpdatedChatConversation({ data: newMsg }));
        }
      });
    }
    return function clean() {
      // dispatch(clearConversationData());
      setConversation([]);
      setPage(1);
      socket.close()
    }
  }, []);
  return (
    <div
      id="scrollableDiv"
      style={{
        // display: 'flex',
        flexDirection: "column-reverse",
        height: "450px !important",
      }}
      className="comments-section"
    >
      <InfiniteScroll
        loadMore={() => getThreadMessages(page + 1)}
        hasMore={conversation?.length < totalChat}
        loader={loader}
        isReverse={true}
        useWindow={false}
        threshold={10}
        scrollabletarget="scrollableDiv"
        style={{ flexDirection: "column-reverse", height: "450px !important" }}
      >
        {/* {messages?.map((item, i) => { */}
        {conversation?.map((item, i) => {
          return (
            <div key={i}>
              {userData.userName !== item?.createdBy?.userName && (
                <div key={"Conversation-" + i} className="comments-card">
                  <Avatar
                    className="avatar"
                    style={{
                      backgroundColor:
                        colors[Math.floor(Math.random() * colors.length)],
                      color: AppTheme.colors.primary,
                    }}
                  >
                    {" "}
                    {item?.createdBy?.innitials}
                  </Avatar>
                  <div className="comments-message-card">
                    <Typography gutterBottom className="comments-message">
                      {item.message}
                    </Typography>
                  </div>
                  <Typography
                    gutterBottom
                    className="comments-date"
                    variant="h6"
                  >
                    {moment(item?.updatedAt).format("DD-MMM-YY hh:mm")}
                  </Typography>
                </div>
              )}
              {userData.userName == item?.createdBy?.userName && (
                <div key={"Conversation" + i} className="comments-card">
                  <Avatar
                    className="my-avatar"
                    style={{ backgroundColor: "#C0D9FF", color: "#00057C" }}
                  >
                    {" "}
                    {item?.createdBy?.innitials}
                  </Avatar>
                  <div className="comments-message-card">
                    <Typography gutterBottom className="comments-message">
                      {item.message}{" "}
                    </Typography>
                  </div>
                  <Typography
                    gutterBottom
                    className="comments-date"
                    variant="h6"
                  >
                    {moment(item?.updatedAt).format("DD-MMM-YY hh:mm a")}
                  </Typography>
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default Conversation;
