import React, { useEffect, useState } from 'react';
import ProjectLabelType from './ProjectLabelType';

const WorkCardLabel = ({ cardType, child_cards }) => {
    const [previousCardType, setPreviousCardType] = React.useState(null);

    const getUniqueLabelIcon = (array: []) => {
        let temp = [];
        for (let index = 0; index < array?.length; index++) {
            const sub = array[index]?.labelNames;
            if (cardType === previousCardType) {
                for (let index2 = 0; index2 < sub?.length; index2++) {
                    const element = sub[index2];
                    if ((array[index].typeOfCard === cardType)) {
                        const findIndex = temp.findIndex((e) => e === element)
                        if (findIndex == -1) {
                            temp.push(element)
                        }
                    }
                }
            }
            else {
                setPreviousCardType(cardType);
                temp = [];
            }

        }
        return temp;
    }
    return (
        <div>
            {getUniqueLabelIcon(child_cards)?.map((labelIcon, i) => {
                return (
                    <ProjectLabelType
                        key={i}
                        labelType={labelIcon} />
                )
            })}
        </div >
    )
}

export default WorkCardLabel;