import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import ReactDatePicker from "@material-ui/pickers/DatePicker";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { useAsyncDebounce } from "react-table";
import TableServices from "services/table";
// import handle_error from '../../../utils/handle';
import { AppTheme } from "theme/customeTheme";
import DownloadArrow from "../../../assets/download_aero.svg";
import "./table.css";

function handle_error(params) {
  console.log("handle_error==", params);
}
const customStyles = {
  control: (base, state) => ({
    ...base,
    borderWidth: "1px",
    borderColor: AppTheme.colors.primary,
    borderRadius: "3px",
    boxShadow: "none",
    "&:hover": { borderColor: "none" },
    background: state.hasValue ? "#fbe4a3" : "",
    minHeight: "37px",
  }),
  placeholder: (base, state) => ({
    fontSize: "11px",
    color: "#90AEBB",
    fontWeight: "500",
    margin: "0 auto",
  }),
  menu: (base) => ({ ...base, borderRadius: 0, marginTop: 0, width: "220px" }),
  menuList: (base) => ({ ...base, padding: 0, whiteSpace: "normal" }),
  valueContainer: (base) => ({
    ...base,
    borderColor: null,
    marginRight: "1px",
    padding: 0,
    paddingLeft: "4px",
    display: "flex",
  }),
  indicatorSeparator: (base) => ({ ...base, margin: 0, width: 0 }),
  indicatorsContainer: (base) => ({
    ...base,
    borderColor: null,
    boxShadow: null,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    borderColor: null,
    boxShadow: null,
  }),
  multiValue: (base) => ({
    ...base,
    paddingLeft: 0,
    paddingRight: 0,
    background: "white",
  }),
  multiValueRemove: (base) => ({
    ...base,
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": { backgroundColor: "transparent" },
  }),
  multiValueLabel: (base) => ({ ...base, paddingLeft: "1px", paddingRight: 0 }), //whiteSpace: "normal"
  clearIndicator: (base) => ({
    ...base,
    paddingLeft: 0,
    paddingRight: 0,
    background: "#fbe4a3",
  }),
};
const CaretDownIcon = (props) => {
  return <img src={DownloadArrow} alt="chevron-down" />;
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon props={props} />
    </components.DropdownIndicator>
  );
};
const selectStyle = {
  borderColor: AppTheme.colors.primary,
  borderRadius: "3px",
  // border: '1px solid #90AEBB',
  // margin: '10px 0px 10px 10px',
  width: "120px",
  height: "37px",
  padding: "3px 13px",
  borderWidth: "1px",
  textAlignLast: "center",
};

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          borderRadius: "10px",
        }}
      />
    </span>
  );
}

export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const defaultRef = React.useRef();
  const resolvedRef = defaultRef;

  // React.useEffect(() => {
  //     console.log("Useeffect");
  //     // setFilter(filterValue)
  //     var event = new Event('input', { bubbles: true });
  //     resolvedRef.current.onChange();
  // }, [filterValue])
  // const count = preFilteredRows.length
  // console.log("filterValue => ", filterValue);
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      style={{
        ...selectStyle,
      }}
      // ref={resolvedRef}
      // className="filter-box"
      // placeholder={`Search ${count} records...`}
    />
  );
}

export const getSrNo = (props) =>
  props.state?.pageSize * (props.state?.pageIndex - 1) + props?.row?.index + 1;

// Filter for Start and End Date
export function TableDateRangeFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const inputRef = useRef();

  return (
    <ReactDatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      ref={inputRef}
      onChange={(update) => {
        console.log("ReactDatePicker update", update);
        setDateRange(update);
        if (update[0] && update[1]) {
          inputRef.current.setOpen(false);
          const endDate = new Date(update[1].setHours(23,59,59,999));
          console.log("ReactDatePicker both", endDate);
          setFilter([
            // moment(update[0]).format('YYYY-MM-DD'),
            // update[1] ? moment(update[1]).format('YYYY-MM-DD') : null,
            update[0] || null,
            endDate,
            // (new Date(update[1]) +1) || null
          ]);
        } else if(update[0] && !update[1]){
            console.log('ReactDatePicker only one');
        }else{
            console.log('ReactDatePicker clear');
          setFilter([null, null]);
        }
      }}
      isClearable
      shouldCloseOnSelect={false}
      closeOnScroll={true}
      popperPlacement="bottom-start"
      value={
        (filterValue?.[0] && filterValue?.[0] != null
          ? moment(filterValue[0], "YYYY-MM-DD").format("DD-MM-YYYY")
          : "") +
        " - " +
        (filterValue?.[1] && filterValue?.[1] != null
          ? moment(filterValue[1], "YYYY-MM-DD").format("DD-MM-YYYY")
          : "")
      }
      className="custom-date-picker"
      wrapperClassName="custom-wrapper pr-0"
    />
  );
}

// Filter for Month only
export function TableDateInMonthFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <ReactDatePicker
      selected={filterValue ? new Date(filterValue) : null}
      onChange={(date) => {
        if (date) {
          setFilter(moment(date).format("YYYY-MM-DD"));
        } else {
          setFilter(date);
        }
      }}
      dateFormat="dd MMM yyyy"
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={15}
      // yearItemNumber
      isClearable
      className="filter-box"
      placeholderText="Select Date"
      // className="custom-date-picker"
      wrapperClassName="custom-wrapper pr-0"
    />
  );
}

//doFilter Method
export const doFilter = (filters, filterMap) => {
  const filtersToPass = {};
  filters?.length > 0 &&
    filters.forEach((filter) => {
      if (filterMap[filter?.id] && Array.isArray(filterMap[filter?.id])) {
        const fieldsArr = filterMap[filter?.id];
        filtersToPass[fieldsArr[0]] = filter?.value[0];
        filtersToPass[fieldsArr[1]] = filter?.value[1];
      } else if (filterMap[filter?.id]) {
        if (Array.isArray(filter?.value)) {
          const filterValues = filter?.value?.map((itm) => itm.value);
          filtersToPass[filterMap[filter?.id]] = filterValues;
        } else filtersToPass[filterMap[filter?.id]] = filter?.value?.trim();
      } else alert("missting entry " + filter?.id);
    });
  return filtersToPass;
};

// Number Range Filter
export function NumberRangeFilter({ column: { filterValue, setFilter } }) {
  const [hourRange, setHourRange] = useState([null, null]);
  const [startPoint, endPoint] = hourRange;
  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(0);
  const setClear = () => {
    console.log("setClear");
    setFilter(null);
    let clearHour = [null, null];
    setHourRange(clearHour);
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <input
          value={startPoint || ""}
          type="number"
          onChange={(event) => {
            let selectedOptions = event.target.value;
            let selectedMinHour = [parseFloat(selectedOptions), endNumber];
            setStartNumber(parseFloat(selectedOptions));
            setHourRange(selectedMinHour);
          }}
          style={{
            width: "50px",
            height: "25px",
            borderColor: AppTheme.colors.primary,
            borderWidth: "1px",
            borderRadius: "3px",
            marginRight: "0.5rem",
            padding: "5px",
          }}
        />
        to
        <input
          value={endPoint || ""}
          type="number"
          onChange={(e) => {
            let selectedOptions = e.target.value;
            let selectedMinHour = [
              startNumber ? startNumber : 0,
              parseFloat(selectedOptions),
            ];
            setEndNumber(parseFloat(selectedOptions));
            setHourRange(selectedMinHour);
          }}
          style={{
            width: "50px",
            height: "25px",
            borderColor: AppTheme.colors.primary,
            borderWidth: "1px",
            borderRadius: "3px",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
            padding: "5px",
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <button
          style={{ width: "60px", marginTop: "5px", marginRight: "10px" }}
          disabled={endNumber > 0 ? false : true}
          onClick={(e) => setFilter(hourRange)}
        >
          click
        </button>
        {/* <button style={{ width: "60px", marginTop: "5px", marginRight: "10px" }} onClick={(e) => setFilter(null)}>clear</button> */}
        <button
          style={{ width: "60px", marginTop: "5px", marginRight: "10px" }}
          onClick={(e) => setClear()}
        >
          clear
        </button>
      </div>
    </div>
  );
}
// Project Labels MultiSelect Filter
export function LabelFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const dispatch = useDispatch();
  const { allProjectLabels } = useSelector(({ table }) => table);
  React.useEffect(() => {
    dispatch(TableServices.getAllProjectLabel());
  }, []);
  // Render a multi-select box
  return (
    <Select
      isMulti
      value={filterValue || []}
      options={allProjectLabels}
      placeholder="Highlighter.."
      styles={{...customStyles, menu: provided => ({ ...provided, zIndex: 2 }) }}
      maxMenuHeight={200}
      onChange={(xx) => {
        setFilter(xx);
      }}
      components={{ DropdownIndicator }}
    />
  );
}
// Work Card Labels MultiSelect Filter
export function WorkCardLabelFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const dispatch = useDispatch();
  const { allWorkCardLabels } = useSelector(({ table }) => table);
  React.useEffect(() => {
    dispatch(TableServices.getAllWorkCardLabel());
  }, []);
  // Render a multi-select box
  return (
    <Select
      isMulti
      value={filterValue || []}
      options={allWorkCardLabels}
      placeholder="Highlighter.."
      styles={{...customStyles, menu: provided => ({ ...provided, zIndex: 2 }) }}
      maxMenuHeight={200}
      onChange={(xx) => {
        setFilter(xx);
      }}
      components={{ DropdownIndicator }}
    />
  );
}
// Folder MultiSelect Filter
export function FoldersFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const dispatch = useDispatch();
  const { folderData } = useSelector(({ table }) => table);
  React.useEffect(() => {
    dispatch(TableServices.getFoldersData());
  }, []);
  // Render a multi-select box
  return (
    <Select
      isMulti
      value={filterValue || []}
      options={folderData}
      placeholder="Folders.."
      styles={{...customStyles, menu: provided => ({ ...provided, zIndex: 2 }) }}
      classNamePrefix="my-select"
      maxMenuHeight={200}
      onChange={(xx) => {
        setFilter(xx);
      }}
      // styles={{ menu: (base) => ({ ...base, zIndex: 2 }) }}
      components={{ DropdownIndicator }}
    />
  );
}
// Managers MultiSelect Filter
export function ManagersFilter({ column: { filterValue, setFilter } }) {
  const dispatch = useDispatch();
  const { managerData } = useSelector(({ table }) => table);

  // Render a multi-select box
  return (
    <Select
      isMulti
      value={filterValue || []}
      options={managerData}
      placeholder="Select.."
      styles={{...customStyles, menu: provided => ({ ...provided, zIndex: 2 }) }}
      maxMenuHeight={200}
      onChange={(xx) => {
        setFilter(xx);
      }}
      components={{ DropdownIndicator }}
    />
  );
}

// static options filter
export function StaticOptionsFilter(
  { column: { filterValue, setFilter } },
  options
) {
  // Render a multi-select box
  return (
    <select
      value={filterValue || "All"}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={{
        ...selectStyle,
      }}
      className="custom-select-dropdown"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// static options filter object based
export function StaticOptionsKeyValueFilter(
  { column: { filterValue, setFilter } },
  options
) {
  // Render a multi-select box
  return (
    <select
      value={filterValue || "All"}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={{
        ...selectStyle,
      }}
      className="custom-select-dropdown"
    >
      <option value="">Select</option>
      {options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
