import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Toc } from "@material-ui/icons";
const DND_ITEM_TYPE = 'row'

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            padding: '5px!important'
        }
    }
});
export default function DraggableRow({ row, index, moveRow, tableView }) {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)
    const classes = useStyles();

    const [collectedProps, drop] = useDrop({
        accept: DND_ITEM_TYPE,

        drop(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            //     console.log("DraggableRow--dropRef drag small--", dropRef);
            //     return
            // }
            // // Dragging upwards
            // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            //     console.log("DraggableRow--dropRef drag big--", dropRef);
            //     return
            // }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: { type: DND_ITEM_TYPE, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    return (

        <TableRow className={classes.root} ref={dropRef} style={ row?.original?.child_cards?.reduce((n, {closed}) => n && closed, true) ? 
            {opacity }:{opacity}} {...row.getRowProps()}>
            {
                (tableView === 'CustomOrder' || tableView ===  'CustomOrder2' || tableView === 'DeliveredReadyProject') &&
                <TableCell
                    style={{
                        cursor: 'pointer',
                    }} ref={dragRef}><Toc /></TableCell>
            }
            {row.cells.map(cell => {
                return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
            })}
        </TableRow>

    )
}