import React, { useEffect, useState } from 'react';
import { AppTheme } from "theme/customeTheme";
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { styled, withStyles } from '@material-ui/styles';
import ProjectLabelType from './ProjectLabelType';
import { trelloLabels } from 'theme/trelloLabels';

const StyledMenu = styled((props) => (
    <Popover
        elevation={0}
        {...props}
    />
))(({ }) => ({
    '& .MuiPaper-root': {
        boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25);',
    },
}));
const CustomMenuItem = withStyles({
    root: {
        fontFamily: '"Poppins", sans-serif',
        color: "#4E4E4E",
        fontSize: 16,
        padding: "10px 15px",
        borderRadius: 16,
        margin: 16,
        letterSpacing: 0.2,
        minHeight: "auto",
        fontWeight: 500,
        border: "4px solid #96C0FF",
        fontStyle: "italic",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // '&:hover': {
        //     backgroundColor: AppTheme.colors.primary,
        //     color: "#FFFFFF"
        //     // borderRadius: 5,
        // }
    }
})(MenuItem)
const CustomStatus = ({ hours, cardType, child_cards }) => {
    const [previousCardType, setPreviousCardType] = React.useState(null);

    const getUniqueListName = (array: []) => {
        let uniqueList = [];
        for (let index = 0; index < array?.length; index++) {
            const sub = array[index]?.listName;
            if (cardType === previousCardType) {
                if ((array[index].typeOfCard === cardType)) {
                    const findIndex = uniqueList.findIndex((e) => e === sub)
                    if (findIndex == -1) {
                        uniqueList.push(sub)
                    }
                }
            }
            else {
                setPreviousCardType(cardType);
                uniqueList = [];
            }

        }
        return uniqueList;
    }

    return (
        <div>
            {
                getUniqueListName(child_cards)?.map((listName, i) => {
                    return (
                        <p key={i}>{listName}</p>
                    )
                })
            }
        </div >
    )
}

export default CustomStatus;