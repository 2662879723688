import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import MessageIcon from "@material-ui/icons/Message";
import ShareIcon from "@material-ui/icons/Share";
import EmailIcon from "@material-ui/icons/Email";
import CakeIcon from "@material-ui/icons/Cake";
import CmtMediaObject from "../../../../../../@coremat/CmtMediaObject";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";
import { getDateElements } from "../../../../../utils/dateHelper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { alpha } from "@material-ui/core/styles";
import moment from "moment";
import { useDispatch } from "react-redux";
import TableServices from "services/table";
import { Redirect, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import CommentsDialogBox from "@jumbo/components/TableComponents/Comments/CommentsDialogBox";

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: "10px 0",
    position: "relative",
    fontFamily: "poppins",
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.035)}`,
    "& .Cmt-media-object": {
      alignItems: "center"
    },
    "& .Cmt-media-image": {
      alignSelf: "flex-start",
      width: 56
    },
    "& .Cmt-media-body": {
      width: "calc(100% - 56px)",
      flex: "inherit"
    }
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: "pointer"
  },
  notificationCard: {
    color: theme.palette.common.white,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  },
  notificationContent: {
    fontSize: "16px",
    color: '#000000',
    cursor: "pointer"
  },
  notificationTime: {
    fontSize: "14px",
    color: '#B3B3B3'
  },
  // cardContent: {
  //   paddingLeft: '15px'
  // },
}));

const getInvitationContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.metaData.user.name}
    </Box>
    <Box component="span" color="blue" ml={1}>
      {item.metaData.group.name}
    </Box>
  </Typography>
);

const NotificationItem = ({ item, onClose, handleDialogBox }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // console.log("notification Item::".item);
  const classes = useStyles();
  // console.log("location----", location);
  // console.log("location.pathname----", location.pathname);
  const handleOnClick = (item) => {
    console.log("handleOnClick item:::", item);
    onClose();
    if (item?.notificationType == "NEW_PROJECT") {
      if (location.pathname !== "/table") {
        history.push('/table');
      }
      dispatch(TableServices.getTableData({
        limit: 10,
        currentPage: 1,
        orderByColumn: null,
        columnOrderType: null,
        name: item?.desc?.replace(" created",""),
        currentView:"Latest",
        projectId:item?.projectCardId

      }));
    }
    if (item?.notificationType == "TAGGED") {
      if (location.pathname !== "/table") {
        history.push('/table');
      }
      handleDialogBox(true, item)
    }
  };
  return (
    <Box className={classes.feedItemRoot} onClick={() => handleOnClick(item)}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.cardContent}>
        <Box display="flex" width="275px" alignItems="flex-start">
          {
            item?.notificationType === 'NEW_PROJECT' &&
            <img src="/images/new project.png" className="New project" />
          }
          {
            item?.notificationType === 'TAGGED' &&
            <img src="/images/mention.png" className="mention" />
          }
          {
            item?.notificationType === 'TASK_ASSIGNED' &&
            <img src="/images/assignment.png" className="Assignment" />
          }

          {/* <img src="/images/completed.png" className="completed" /> */}


          {/* <img className="pointer" src={item?.icons} alt="mention" /> */}
          <Box marginX="10px">
            <a className={classes.notificationContent}>{item?.notificationText}</a>
            {
              item?.notificationType == "NEW_PROJECT" &&
              // <div className="notification-desc">{item?.projectCardId?.card_title?.name} {item?.projectCardId?.card_title?.desc} </div>
              <div className="notification-desc">{item?.desc} </div>
            }
          </Box>
        </Box>

        <Box display="flex">
          <em><span className={classes.notificationTime}>{moment(item?.times).fromNow()}</span></em>
          <CloseIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
