export const AppTheme = {
    colors: {
        primary: "#006D8F",
        white: '#FFFFFF',
        black: '#000000'
    },
    CustomLabelcolors: {
        green: "#58B000",
        red: '#FF0000',
        yellow: '#FFB905',
    },
    BadgeBackgroundColors: {
        pink: "#FFF5FB",
        orange: "#FFDBC0",
        green: '#BEF9C4',
        // green: '#F5FFF6',
        lightBlue: '#CEE8FF',
        red: '#FF7676',
        lightBrown: '#DCC9B8'
    },
    BadgeBorderColors: {
        pink: "#FFABDE",
        orange: "#FF783F",
        green: '#71FFAA',
        lightBlue: '#3DA4FF',
        red: '#FF5252',
        lightBrown: '#9F6D40'
    }
}